import React, { useState, useEffect } from 'react';
import 'assets/styles/pages/sidebar.scss';
import 'assets/styles/pages/dashboardstatus.scss';
import Logo from 'assets/images/logo/forest-logo.png';
import { Modal, Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import Form from 'react-bootstrap/Form';
// import editicon from 'assets/images/admin/edit-icon.svg';
import editiconpencil from 'assets/images/admin/edit-icon.svg';
import projecttreeview from 'assets/images/admin/project-view-tree.png';
import eyeicon from 'assets/images/admin/visibility.png';
import binicon from 'assets/images/admin/recycle-bin.png';
import { toast, ToastContainer } from 'react-toastify';

import AdminService from 'services/admin/admin.service';
import moment from 'moment';
import Viewthubnailslider from 'components/register-plantation/viewthumbnail-slider';

import Pagination from "react-js-pagination";
import noimage from "assets/images/noImage.png";

import infoIcon from 'assets/images/icons/info-icon.png';
import { doAdminLogout } from './login';

//import Initiativeviewspecies from 'components/admin/initiative-view';
export default function Dashboardstatus() {

  const adminApi = new AdminService();
  const [plantationList, setPlantationList] = useState([]);
  const [projectList, setProjectList] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [projectValue, setProjectValue] = useState("");
  const [deleteOpen, setDeleteOpen] = useState("");
  const [projectSearchData, setProjectSearchData] = useState("");
  const [statsuSearchVal, setStatsuSearchVal] = useState("");
  const [modifiedSearchVal, setModifiedSearchVal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [plantationData, setPlantationData] = useState('');
  const [pageCount, setPageCount] = useState(10)

  const handlePageChange = async (pageNumber) => {
    await setActivePage(pageNumber)
    let inputs = {
      "pageNumber": pageNumber - 1,
      "pageSize": pageCount,
      "reviewStatus": statsuSearchVal,
      "projectId": projectSearchData,
      "toBeFiltered": (projectSearchData !== "" || statsuSearchVal !== "") ? true : false

    }
    setLoading(true);
    await adminApi.getPlantationList({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data.plantationDetails);
          setPlantationData(data.data);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      }
      );
  }

  const pageCountChange = async (e) => {
    let targetValue = e.target.value
    setPageCount(targetValue);
    let inputs = {
      "pageNumber": activePage - 1,
      "pageSize": targetValue,
      "reviewStatus": statsuSearchVal,
      "projectId": projectSearchData,
      "toBeFiltered": (projectSearchData !== "" || statsuSearchVal !== "") ? true : false

    }
    setLoading(true);
    await adminApi.getPlantationList({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data.plantationDetails);
          setPlantationData(data.data);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
      }
      );
  }

  const validationMessage = (data) => {
    if (data.response.status === 401) {
      toast.error("Authentication failed");
      setTimeout(() => {
        doAdminLogout()
        navigate("/admin/")
      }, 3000)
    } else if (data.response.status === 403) {
      toast.error("Un Authorized user");
      setTimeout(() => {
        doAdminLogout()
        navigate("/admin/")
      }, 3000)
    } else {
      toast.error(data.response.data.message);
    }
  }

  const listPlantations = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": pageCount,
      "reviewStatus": 0,
      "projectId": 0,
      "toBeFiltered": false

    }
    setLoading(true);
    await adminApi.getPlantationList({ inputs })
      .then(data => {
        if (data.status === 200) {
          setPlantationList(data.data.plantationDetails);
          setPlantationData(data.data);
          setLoading(false)
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error);
        }
      }
      );
  };
  const searchSpecies = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": 10,
      "reviewStatus": statusValue,
      "projectId": projectValue,
      "toBeFiltered": true
    }
    setLoading(true);
    const plantationResponse = await adminApi.getPlantationList({ inputs });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setPlantationList(plantationResponse.data.plantationDetails);
        setLoading(false)
      } else {
        setLoading(false)
        toast.error("Something went wrong");
      }
    } else {
      setLoading(false)
    }
  }
  const viewPage = (data, type) => {
    const editData = data
    editData.type = type
    navigate("/admin/adminview/", { state: { editData } })
  }
  const editPage = (data) => {
    const editData = data
    editData.type = "edit"
    navigate("/admin/adminview/", { state: { editData } })
  }

  const listPlanters = async () => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": pageCount,
      "name": "",
      "status": "",
      "toBeFiltered": false
    }
    setLoading(true);
    await adminApi.listProjects({ inputs })
      .then(data => {
        if (data.status === 200) {
          setLoading(false);
          setProjectList(data.data);
        } else {
          setLoading(false)
          toast.error("Something went wrong");
        }
      })
      .catch(error => {
        setLoading(false)
        if (error.response) {
          validationMessage(error)
        }
      }
      )
  };
  const projectSearch = async (project, status, mstatus) => {
    let inputs = {
      "pageNumber": 0,
      "pageSize": pageCount,
      "reviewStatus": status === "" ? "" : status,
      "projectId": project,
      "growthStatusToBeFiltered": mstatus,
      "growthStatusModified": mstatus,
      "toBeFiltered": (project !== "" || status !== "") || mstatus === true ? true : false
    }
    setLoading(true);
    const plantationResponse = await adminApi.getPlantationList({ inputs });
    if (plantationResponse !== undefined) {
      if (plantationResponse.status === 200) {
        setPlantationList(plantationResponse.data.plantationDetails);
        setPlantationData(plantationResponse.data);
        setLoading(false)
      } else {
        setLoading(false)
        toast.error("Something went wrong");
      }
    } else {
      setLoading(false)
    }
  }
  const statusSearch = async (e) => {
    let proValue = e.target.value
    await setActivePage(1)
    await setLoading(true)
    await setStatsuSearchVal(proValue);;
    await setLoading(false)
    await projectSearch(projectSearchData, proValue, modifiedSearchVal);
  }
  const modifiedStatusSearch = async (e) => {
    let proValue = e.target.value
    await setLoading(true)
    await setModifiedSearchVal(e.target.value);
    await setLoading(false)
    await projectSearch(projectSearchData, statsuSearchVal, proValue);
  }

  const deletePlan = async () => {
    setLoading(true);
    await adminApi.deletePlantation(id).then(data => {
      if (data.status === 200) {
        toast.success("Plantation Deleted Successfully");
        listPlantations();
        setLoading(false)
        handleClose();
      } else {
        setLoading(false)
        toast.error("Something went wrong");
      }
    })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.message);
        handleClose();
      }
      );
  }
  const deleteOpenTab = (data) => {
    setDeleteOpen(true);
    setId(data)
  };
  const projectSea = async (data) => {
    await setProjectSearchData(data);
    await projectSearch(data, statsuSearchVal, modifiedSearchVal)
  };
  const handleClose = () => setDeleteOpen(false);
  useEffect(() => {
    listPlantations();
    listPlanters();
  }, []);
  return (
    <>
      <div className='dashboard-status-wrapper'>
        <div className='row'>
          <div className='col-md-12'>
            <h3 className='dark-theme-green margin-btm'> PLANTING DETAILS </h3>
          </div>
          <div className='col-md-3'>
            <div className='project-status-select'>
              <Form.Select className='projectselect all-project-input-box' aria-label='All Projects' onChange={(e) => { projectSea(e.target.value) }}>
                <option>All Projects</option>
                {projectList.projects && projectList.projects.map((item, index) => {
                  return (<option key={index + 1} value={item.id}>{item.name}</option>)
                })}
              </Form.Select>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='project-status-select'>
              <Form.Select className='all-status-input-box' aria-label='All status' onChange={(e) => { statusSearch(e) }}>
                <option value="0">All status</option>
                <option value='1'>Pending </option>
                <option value='2'>In Progress </option>
                <option value='3'>Approved</option>
                <option value='4'>Rejected</option>
              </Form.Select>&nbsp;&nbsp;
            </div>
          </div>
          <div className='col-md-3'>
            <div className='project-status-select'>
              <Form.Select className='all-status-input-box' aria-label='All status' onChange={(e) => { modifiedStatusSearch(e) }}>
                <option value="0">Modified Status</option>
                <option value='true'>True </option>
                <option value='false'>False </option>
              </Form.Select>
            </div>
          </div>
          <div className='col-md-3'>
            <Link to='/admin/uploadplantation' className='btn btn-white-button fl-right'>
              + Upload Plantation
            </Link>
          </div>
          {/* <div className='col-md-4'>
          <Form.Control className='search-box-input' type='text' placeholder='' onChange={(e) => { searchSpecies(e) }} />
        </div> */}
        </div>
        <div className='row mg-top20'>
          {plantationData && plantationData.totalNoOfRecords > 0 ? <>
            {plantationList && plantationList.map(item => {
              let loopImages = item.plantation.trackingDetails && item.plantation.trackingDetails[0] ? item.plantation.trackingDetails[0] : ""
              return <div className='col-md-6' key={item.plantation.id}>
                <div className='dashboard-card-wrapper' key={item.plantation.id}>
                  <div className='wid-40 padding-10' id='adminplantList'>
                    {/* <img className='img-fluid' src={item.plantation.trackingDetails[0].imagesLogs[0].imageUrl} alt='bellicon' /> */}
                    {loopImages.imagesLogs && loopImages.imagesLogs ? <Viewthubnailslider images={loopImages.imagesLogs} key={item.id} /> : <img src={noimage} alt="default Image" />}
                  </div>
                  <div className='dashboard-card-body wid-60'>
                    <div className='dis-title-status'>
                      <h5 className='heading-text'> {item.plantation.project.name}</h5>
                    </div>
                    <div className='dis-title-status small-tree-text'>
                      <span className='small-tree-text mg-right50'>Total Trees</span>
                      <span className='small-tree-text'>{item.totalSpeciesCount}</span>
                    </div>
                    <div className='dis-title-status small-tree-text'>
                      <span className='small-tree-text mg-right40'>Available Trees</span>
                      <span className='small-tree-text'>{item.availableSpeciesCount}</span>
                    </div>

                    {/* <p className='small-tree-text mg-right40'>Total Trees {item.totalSpeciesCount}</p>
                    <p className='small-tree-text mg-right40'>Available Trees {item.availableSpeciesCount}</p> */}
                    <div className='mgtop10'></div>
                    <p className='reg-id-num ellispe'> Reg Id # {item.plantation.registrationId} </p>
                    <p className='date-time-text'> Planted by : <b>{item.plantation.planterName} </b></p>
                    <p className='date-time-text'> Registered by : <b>{item.plantation.plantationRegistrarName} </b></p>
                    <p className='date-time-text'> Reviewed by : <b>{item.plantation.reviewedBy ? item.plantation.reviewedBy : "Not Yet"}</b></p>
                    <p className='date-time-text'> Mobile No : {item.plantation.planterPhoneNumber} </p>
                    <div className='item-direction-wr1'>
                      <p className='date-time-text p-wid70'> {moment(item.plantation.plantationDate).format(("ddd, MMM D YYYY"))} </p>
                      <span className='sp30'>

                        <button className='circle-view margin-right-10' to='/admin/adminview/' onClick={() => { viewPage(item, "view") }}>

                          <img className='img-fluid eye-max-w' src={eyeicon} alt='bellicon' />
                        </button>
                        {item.plantation.reviewStatus === "IN_PROGRESS" || item.plantation.isGrowthStatusModified === true ? <button className='circle-view' onClick={() => { viewPage(item, "edit") }}>

                          <img className='img-fluid eye-max-w' src={editiconpencil} alt='bellicon' />
                        </button> : null}
                        {item.plantation.reviewStatus === "REJECTED" ? <button className='circle-view-admin delete-bin' onClick={() => { deleteOpenTab(item.plantation.id) }}>
                          <img className='img-fluid bin-w-pad' height="40px" width="25px" src={binicon} alt='bellicon' />
                        </button> : null}
                      </span>
                    </div>
                    {item.plantation.isGrowthStatusModified === true ?
                      <p className='date-time-text track-color '>Modified plantation waiting for approval</p> : null}
                    {item.plantation.reviewStatus === "IN_PROGRESS" ?
                      <p className='date-time-text track-color'>New plantation waiting for approval</p> : null}
                    {item.plantation.reviewStatus === "REJECTED" ?

                      <p className='date-time-text hastric-color'>Plantation has Rejected  <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip-top'>
                            <strong>{item.plantation.reviewComment}</strong>.
                          </Tooltip>
                        }
                      >
                        <img src={infoIcon} alt="info" width="18px" height="18px" /></OverlayTrigger></p>
                      : null}
                  </div>
                </div>
              </div>
            })}</> : <p>No Records Found</p>}

        </div>
        {(loading === false && plantationData.totalNoOfRecords > 10) ? <div className='row'>
          <div className='col-md-4 float-start'>Showing {activePage === 1 ? activePage : (activePage - 1) * pageCount} to {Number(activePage) * pageCount} of {plantationData.totalNoOfRecords} items </div>
          <div className='col-md-5 align-me'>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageCount}
              totalItemsCount={plantationData.totalNoOfRecords}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
          </div>
          <div className='col-md-3 float-right' id="select-width">
            <span> Show <Form.Select onChange={pageCountChange} id="select-width" value={pageCount}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </Form.Select> entries</span>
          </div>
        </div> : null}
      </div>
      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <Modal show={deleteOpen} centered>
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Plantation Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="whiteColor" style={{ color: "black" }} onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={deletePlan}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}
