import React from 'react';

import AdminLayout from '../../adminlayout/index';

import SEO from 'components/seo/index';

import Dashboardstatus from 'components/admin/dashboardstatus';

const AdminPagedshboardpage = () => {
  return (
    <>
      <SEO title='Forests By Heartfulness admin' />
      <AdminLayout>
        <div>
          <Dashboardstatus />
        </div>
      </AdminLayout>
    </>
  );
};

export default AdminPagedshboardpage;
